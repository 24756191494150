import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Stream",
    component: () => import("../views/Stream.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/poll",
    name: "Poll",
    component: () => import("../views/Polls.vue"),
  },
  {
    path: "/questions",
    name: "Questions",
    component: () => import("../views/Questions.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
