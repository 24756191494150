import { createStore } from "vuex";
import { db, serverTimestamp } from "../firebase";
import {
  ADD_POLL_QUESTION,
  DELETE_POLL_QUESTION,
  FETCH_POLL_QUESTIONS,
  SET_POLL_QUESTIONS,
  SET_LOADING,
  SET_SETTINGS,
  SUBSCRIBE_SETTINGS,
} from "./actions";

export default createStore({
  state: {
    pollQuestions: [],
    loading: {
      settings: false,
      pollQuestions: false,
      newPoll: false,
    },
    settings: {
      url: "",
      question: "",
      message: "",
      live: false,
    },
  },
  mutations: {
    [SET_POLL_QUESTIONS](state, payload) {
      state.pollQuestions = payload;
    },

    [SET_LOADING](state, payload) {
      state.loading = {
        ...state.loading,
        ...payload,
      };
    },

    [DELETE_POLL_QUESTION](state, payload) {
      state.pollQuestions = state.pollQuestions.filter(q => q.id !== payload);
    },

    [ADD_POLL_QUESTION](state, payload) {
      state.pollQuestions = [...state.pollQuestions, payload];
    },

    [SET_SETTINGS](state, payload) {
      state.settings = { ...state.settings, ...payload };
    },
  },
  actions: {
    async [FETCH_POLL_QUESTIONS](context) {
      context.commit(SET_LOADING, { pollQuestions: true });
      const pollQuestions = (
        await db
          .collection("questions")
          .orderBy("createdAt")
          .get()
      ).docs.map(d => ({
        id: d.id,
        ...d.data(),
      }));
      context.commit(SET_POLL_QUESTIONS, pollQuestions);
      context.commit(SET_LOADING, { pollQuestions: false });
    },

    async [DELETE_POLL_QUESTION](context, questionId) {
      context.commit(SET_LOADING, { pollQuestions: true });
      await db
        .collection("questions")
        .doc(questionId)
        .delete();
      context.commit(DELETE_POLL_QUESTION, questionId);
      context.commit(SET_LOADING, { pollQuestions: false });
    },

    async [ADD_POLL_QUESTION](context, { title, answers }) {
      context.commit(SET_LOADING, { newPoll: true, pollQuestions: true });

      const questionRef = await db.collection("questions").add({
        title: title,
        createdAt: serverTimestamp,
      });

      const batch = db.batch();
      const answersRef = questionRef.collection("answers");

      answers.forEach(answer => {
        if (answer.text) {
          batch.set(answersRef.doc(), {
            text: answer.text,
            order: answer.index,
            count: 0,
          });
        }
      });

      await batch.commit();
      const id = (await questionRef.get()).id;

      context.commit(ADD_POLL_QUESTION, { id, title });
      context.commit(SET_LOADING, { newPoll: false, pollQuestions: false });
    },

    async [SUBSCRIBE_SETTINGS](context) {
      context.commit(SET_LOADING, { settings: true });

      db.collection("settings")
        .doc("stream")
        .onSnapshot(snapshot => {
          const settings = snapshot.data();
          context.commit(SET_SETTINGS, settings);
          if (context.state.loading.settings) {
            context.commit(SET_LOADING, { settings: false });
          }
        });
    },
  },
});
